import { Modal, Button, message } from 'antd';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY, createStripePaymentIntent } from '../../functions';

interface AddChannelModalProps {
  visible: boolean;
  onClose: () => void;
  serviceName: string | null;
  serviceTitle: string;
  currentChannels: string[];
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CHANNEL_PRICES = {
  'google_ads': 350,
  'meta_ads': 350,
  'seo': 350,
  'business_listings': 50,
  'text_marketing': 15,
  'email_marketing': 5,
  'social_media': 10
};

const USAGE_FEES = {
  'text_marketing': '$0.03 per message',
  'email_marketing': '$0.003 per email',
  'social_media': '$1 per generated post'
};

const MAX_MONTHLY_COST = 600;

const calculateMonthlyTotal = (channels: string[]) => {
  const total = channels.reduce((total, channel) => {
    return total + (CHANNEL_PRICES[channel as keyof typeof CHANNEL_PRICES] || 0);
  }, 0);
  return Math.min(total, MAX_MONTHLY_COST); // Cap at $600
};

const AddChannelModal = ({ visible, onClose, serviceName, serviceTitle, currentChannels }: AddChannelModalProps) => {
  const [paymentStage, setPaymentStage] = useState<'confirm' | 'payment' | 'success'>('confirm');
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const basePrice = serviceName ? CHANNEL_PRICES[serviceName as keyof typeof CHANNEL_PRICES] : 0;
  const usageFee = serviceName ? USAGE_FEES[serviceName as keyof typeof USAGE_FEES] : null;

  const currentMonthly = calculateMonthlyTotal(currentChannels);
  const uncappedNewMonthly = currentMonthly + basePrice;
  const newMonthly = Math.min(uncappedNewMonthly, MAX_MONTHLY_COST);
  const savings = uncappedNewMonthly - newMonthly;

  const handleConfirm = async () => {
    try {
      setIsProcessing(true);
      const { clientSecret: newClientSecret } = await createStripePaymentIntent(
        basePrice * 100, // Convert to cents
        `Add ${serviceTitle}`
      );
      setClientSecret(newClientSecret);
      setPaymentStage('payment');
    } catch (error) {
      console.error('Error initializing payment:', error);
      message.error('Failed to initialize payment. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSuccess = () => {
    message.success('Channel added successfully!');
    setPaymentStage('success');
    setTimeout(() => {
      onClose();
      window.location.reload(); // Refresh to show updated channels
    }, 2000);
  };

  const renderConfirmStage = () => (
    <div className="text-center">
      <h3 className="text-2xl font-semibold mb-6">Upgrade Your Plan</h3>
      <div className="text-gray-600 mb-8">
        <p className="mb-2">
          Adding {serviceTitle} will upgrade your subscription:
        </p>
        <div className="mt-4 p-6 bg-gray-50 rounded-lg text-left space-y-4">
          {/* Current Monthly Cost */}
          <div className="pb-4 border-b border-gray-200">
            <p className="text-base font-medium text-gray-600">Current Monthly Cost:</p>
            <p className="text-2xl font-semibold text-gray-900">${currentMonthly}</p>
          </div>
          
          {/* New Addition */}
          <div className="pb-4 border-b border-gray-200">
            <p className="text-base font-medium text-gray-600">Adding:</p>
            <div className="flex justify-between items-center">
              <div>
                <p className="text-lg text-gray-900">{serviceTitle}</p>
                {usageFee && (
                  <p className="text-sm text-gray-500 mt-1">Plus {usageFee}</p>
                )}
              </div>
              <p className="text-lg font-semibold text-[#2563eb]">+${basePrice}/mo</p>
            </div>
          </div>
          
          {/* New Monthly Total - Updated to show usage fee note */}
          <div className="pt-2">
            <div className="flex justify-between items-start pt-2">
              <p className="text-base font-bold text-gray-700">New Monthly Base:</p>
              <div className="text-right">
                <p className="text-2xl font-bold text-[#2563eb]">${newMonthly}/mo</p>
                {usageFee && (
                  <p className="text-sm text-gray-500 mt-1">Plus {usageFee}</p>
                )}
              </div>
            </div>
            
            {savings > 0 && (
              <div className="mt-2 p-3 bg-green-50 rounded-md">
                <p className="text-sm font-medium text-green-700">
                  You save ${savings} with our $600 monthly cap!
                </p>
                <p className="text-xs text-green-600 mt-1">
                  Regular price would be ${uncappedNewMonthly}
                </p>
              </div>
            )}
          </div>

          {newMonthly === MAX_MONTHLY_COST && (
            <div className="mt-2 p-3 bg-blue-50 rounded-md">
              <p className="text-sm font-medium text-blue-700">
                You've reached our All-Inclusive price of $600/month
              </p>
              <p className="text-xs text-blue-600 mt-1">
                Add any additional channels at no extra cost!
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center gap-4">
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          type="primary"
          onClick={handleConfirm}
          loading={isProcessing}
          className="bg-[#2563eb] hover:bg-[#1d4ed8]"
        >
          Upgrade Subscription
        </Button>
      </div>
    </div>
  );

  const renderPaymentStage = () => {
    if (!clientSecret) return <div>Preparing upgrade details...</div>;

    return (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <PaymentForm onSuccess={handleSuccess} />
      </Elements>
    );
  };

  const renderSuccessStage = () => (
    <div className="text-center">
      <h3 className="text-2xl font-semibold mb-6">Subscription Upgraded!</h3>
      <p className="text-gray-600">
        Your plan has been successfully upgraded to include {serviceTitle}.
      </p>
    </div>
  );

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      {paymentStage === 'confirm' && renderConfirmStage()}
      {paymentStage === 'payment' && renderPaymentStage()}
      {paymentStage === 'success' && renderSuccessStage()}
    </Modal>
  );
};

const PaymentForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/dashboard`,
        },
        redirect: 'if_required',
      });

      if (error) {
        message.error(error.message);
      } else if (paymentIntent.status === 'succeeded') {
        onSuccess();
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      message.error('Payment failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold mb-4">Confirm Subscription Upgrade</h3>
      <PaymentElement />
      <div className="flex justify-end">
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={isProcessing}
          disabled={!stripe}
          className="bg-[#2563eb] hover:bg-[#1d4ed8]"
        >
          Confirm Upgrade
        </Button>
      </div>
    </div>
  );
};

export default AddChannelModal; 