
import React, { FC, useState } from "react";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Button } from "antd";

const OAuthCard: FC<{ redirectOAuth: () => {}, title: string, description: string, linked: any }> = ({ redirectOAuth, title, description, linked }) => {
  return (
    <div className="bg-light-100 p-3 rounded-lg flex flex-col justify-between">
      <Title className="!m-0 !mb-3 font-semibold md:!text-lg !text-base text-dark-100 !font-body">
        {title}
      </Title>
      <Paragraph className="!m-0 !mb-3 !font-medium !text-sm !font-body">
        {description}
      </Paragraph>
      {linked != null ? <div className="!flex !justify-center !mt-5">
        {!linked ? <Button
          type="primary"
          // className="!bg-primary-100 !border-primary-100 hover:!bg-white !text-white hover:!text-primary-100 !h-10 !w-full !max-w-220px !text-sm !font-medium !font-poppins !transition-all !ease-in-out !flex justify-center items-center !gap-1"
          onClick={redirectOAuth}
        >
          Link Account
        </Button> : <Button
          type="primary"
          disabled={true}
          // className="!bg-primary-100 !border-primary-100 hover:!bg-white !text-white hover:!text-primary-100 !h-10 !w-full !max-w-220px !text-sm !font-medium !font-poppins !transition-all !ease-in-out !flex justify-center items-center !gap-1"
          onClick={redirectOAuth}
        >
          Account Linked
        </Button>}
      </div> : <div className="!flex !justify-center !mt-5">
        <Button
          type="primary"
          disabled={true}
          loading={true}
          // className="!bg-primary-100 !border-primary-100 hover:!bg-white !text-white hover:!text-primary-100 !h-10 !w-full !max-w-220px !text-sm !font-medium !font-poppins !transition-all !ease-in-out !flex justify-center items-center !gap-1"
          onClick={redirectOAuth}
        >
        </Button>
      </div>}

    </div>
  );
};

export default OAuthCard;
